.app__header {
  padding: 10px;
  text-align: center;
  background-color: #f3f3f354;
}

#app__headerimage {
  width: auto;
  height: 150px;
}

/* h2 {
  color: #858585;
} */

.form {
  background-color: #f2f3f8;
}
/* 
#root {
  background-color: #f2f3f8;
} */

.card {
  max-width: 45%;
  border: 1px solid lightgrey;
  /* border-radius: 15px; */
  padding: 50px;
  margin: 50px auto;
  box-shadow: 6px 16px 18px 7px #888888;
  background-color: white;
  /* font-family: Helvetica, sans-serif; */
}

#card--confirmation {
  text-align: center;
}

.header {
  text-align: center;
  max-width: 60%;
  padding: 45px 350px;
  margin: auto;
  box-shadow: 6px 16px 18px 7px #888888;
  background-color: white;
}

.card__header--image {
  width: auto;
  height: 150px;
}

.form__row {
  padding: 10px;
}

.form__col {
  margin-top: 10px;
}

.form__row--header {
  font-weight: bold;
  margin-bottom: 2px;
}

#card__confirmation--img {
  max-height: 250px;
  width: auto;
}

.sigCanvas {
  width: 100%;
  border: 3px dashed lightgray;
  cursor: crosshair;
}

#signature {
  height: 150px;
  width: auto;
  border: solid 1px lightgray;
}

.submission {
  border: solid 1px lightgray;
  padding: 15px;
}

input[type="text"],
input[type="date"] {
  padding: 10px;
  width: 100%;
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 30px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
  border-radius: 25px;
}

.confirmation-modal-content {
  background-color: #fefefe;
  border: 1px solid #888;
  position: absolute;
  width: 100%;
  top: 0;
  height: 100%;
  text-align: center;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

#submit {
  width: 40%;
  display: flex;
  margin: AUTO;
  padding: 25px;
  border-radius: 25px;
  background-color: #2a96f3;
  color: WHITE;
  place-content: CENTER;
  cursor: pointer;
  border: solid 1px;
}

#clear {
  margin-top: 5px;
}

#header-image {
  max-width: 60%;
}

.copyright-text {
  text-align: center;
  /* font-style: italic; */
  color: #3c3c3c;
}

h4 {
  color: #888;
}

h6 {
  text-align: center;
}

#birthdate {
  padding: 18px;
}

.signup {
  margin: 7% 30%;
}

.signup__button {
  width: 100%;
  display: flex;
  margin: auto;
  padding: 25px;
  background-color: #1B2663;
  color: white;
  place-content: center;
  cursor: pointer;
  border: solid 1px;
}

.box {
  padding: 25px;
  background-color: #1B2663;
}